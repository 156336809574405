var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header-index-wide" },
    [
      _c(
        "a-card",
        {
          style: { height: "100%" },
          attrs: {
            bordered: false,
            bodyStyle: { padding: "16px 0", height: "100%" },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "account-settings-info-main",
              class: { mobile: _vm.isMobile },
            },
            [
              _c(
                "div",
                { staticClass: "account-settings-info-left" },
                [
                  _c(
                    "a-menu",
                    {
                      style: {
                        border: "0",
                        width: _vm.isMobile ? "560px" : "auto",
                      },
                      attrs: {
                        mode: _vm.isMobile ? "horizontal" : "inline",
                        selectedKeys: _vm.selectedKeys,
                        type: "inner",
                      },
                      on: { openChange: _vm.onOpenChange },
                    },
                    [
                      _c(
                        "a-menu-item",
                        { key: "/account/settings/base" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "BaseSettings" } } },
                            [_vm._v(" 基本设置 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "/account/settings/security" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "SecuritySettings" } } },
                            [_vm._v(" 安全设置 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "account-settings-info-right" },
                [
                  _c("div", { staticClass: "account-settings-info-title" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$route.meta.title))]),
                  ]),
                  _c("route-view"),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }